@import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600&display=swap';
@import 'https://fonts.googleapis.com/css?family=Lato&display=swap';
.chat-optin-input {
  width: 70%;
  padding: 5px;
  position: relative;
  left: 10px;
  top: 20px;
  border-radius: 25px;
}
.wa-chat-btn-fixed {
  position: fixed;
  font-family: Lato, Open Sans, sans-serif !important;
}
.wa-chat-btn-fixed img {
  background-color: transparent;
  opacity: 1;
}
.wa-chat-btn-default {
  font-size: 14px;
  text-decoration: none;
  position: fixed;
  bottom: 20px;
  padding: 10px;
  background: #ffffff;
  color: green;
  cursor: pointer;
  box-shadow: 0 0 5px #d3d3d3;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.wa-chat-btn-default-waicon {
  width: 25px;
  vertical-align: middle;
  margin-right: 4px;
}
.wa-chat-btn-base-cta {
  text-decoration: none;
  cursor: pointer;
  -webkit-box-shadow: 0 0 5px 0 #d3d3d3;
  box-shadow: 0 0 5px #d3d3d3;
  display: table;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.wa-chat-btn-base-cta-rounded {
  text-decoration: none;
  cursor: pointer;
  -webkit-box-shadow: 0 0 5px 0 #d3d3d3;
  box-shadow: 0 0 5px #d3d3d3;
  display: table;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}
.wa-chat-btn-base-icon {
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0 0 8px #c2c2c2;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}
.wa-chat-btn-base-icon.no-box-shadow {
  background: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.wa-chat-btn-container-size-big {
  font-size: 14px;
  padding: 10px;
}
.wa-chat-btn-container-size-rounded-big {
  font-size: 14px;
  padding: 10px 16px;
}
.wa-chat-btn-container-size-small {
  font-size: 12px;
  padding: 6px;
}
.wa-chat-btn-container-size-rounded-small {
  font-size: 12px;
  padding: 6px 10px;
}
.wa-chat-btn-icon-cta-big {
  width: 25px;
  vertical-align: middle;
  margin-right: 4px;
}
.wa-chat-btn-icon-cta-small {
  width: 20px;
  vertical-align: middle;
  margin-right: 4px;
}
.wa-chat-btn-icon-image-only {
  width: 54px;
  height: 54px;
  vertical-align: middle;
}
.wa-chat-btn-theme-cta-old {
  background: #f9f9f9;
  color: green;
}
.wa-chat-btn-theme-cta-new {
  background: white;
  color: green;
}
.wa-chat-btn-theme-cta-new-inverted {
  background: #35cb5e;
  color: #fff;
}
.wa-chat-btn-theme-cta-black {
  background: white;
  color: #000;
}
.wa-chat-btn-theme-cta-black-inverted {
  background: black;
  color: #fff;
}
.wa-chat-button-cta-text {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
}
.wa-chat-bubble-floating-popup {
  position: fixed;
  box-shadow: 0 0 30px #0000004d;
  background: #fff;
  overflow: hidden;
  width: 350px;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4;
  bottom: 20px;
  display: block;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  font-family: Lato, Open Sans, sans-serif !important;
}
@media screen and (max-width: 767px) {
  .wa-chat-bubble-floating-popup {
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    font-size: 12px;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
  }
}
.wa-chat-bubble-header-light {
  background: linear-gradient(110.56deg, #20802c 0%, #30bf42 100%);
  text-align: center;
  color: #fff;
  padding: 24px;
}
.wa-chat-bubble-header-dark {
  background: linear-gradient(110.56deg, #000000 0%, #6a6a6a 100%);
  text-align: center;
  color: #fff;
  padding: 24px;
}
.wa-chat-bubble-chat {
  padding: 0;
  background: #ffffff;
  height: 400px;
  overflow-y: auto;
}
.wa-chat-bubble-reachus {
  display: block;
  margin: auto;
  padding-top: 12px;
  padding-bottom: 12px;
}
.wa-chat-bubble-reachus-title {
  font-size: 20px;
  font-weight: 200;
  color: #000;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  line-height: 100%;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .wa-chat-bubble-floating-popup .wa-chat-bubble-chat {
    max-height: 70%;
    height: calc(100vh - 165px) !important;
  }
  .wa-chat-bubble-floating-popup .wa-chat-bubble-chat {
    background: transparent;
  }
}
.wa-chat-bubble-chat .list-cs {
  font-size: 13px;
  padding: 16px;
  overflow: hidden;
  border-bottom: 1px solid #f0f0f0;
  background-color: #d3d3d3;
  display: flex;
  background: #fff;
  cursor: pointer;
}
.wa-chat-bubble-chat .list-cs:last-child {
  margin-bottom: 1.2rem;
  border-bottom: 0px;
}
.wa-chat-bubble-chat .list-cs:first-child {
  border-bottom: 1px solid #f0f0f0;
}
.wa-chat-bubble-avatar {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  overflow: hidden;
  right: 10px;
  border-radius: 60px;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
}
.wa-chat-bubble-whatsapp-avatar {
  position: relative;
  vertical-align: bottom;
  left: 10px;
  z-index: 99;
  height: 16px;
  width: 16px;
}
.wa-chat-bubble-cs-profile {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  color: #000;
  font-size: 14px;
  margin-top: 2px;
  text-align: left;
  flex: 1;
}
.wa-chat-bubble-cs-profile p {
  margin: 0;
  padding: 0;
  line-height: 1;
  color: #555;
  font-weight: 300;
}
.wa-chat-bubble-profile-name {
  display: block;
  font-size: 20px;
  font-weight: 300;
  margin-block-start: 0.3em;
  margin-block-end: 0.4em;
}
.wa-chat-bubble-footer {
  height: 2em;
  position: relative;
}
.wa-chat-bubble-footer-content {
  color: #606060;
  left: 50%;
  margin: 0 auto;
  position: absolute;
  top: 60%;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.wa-chat-bubble-header-title {
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 100%;
  text-align: left;
  color: #fff;
}
.wa-chat-bubble-close-btn {
  float: right;
  cursor: pointer;
  display: table;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.wa-chat-bubble-header-desc {
  margin-top: 12px;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-align: left;
  color: #efefef;
}
.wa-chat-bubble-pos-left {
  left: 20px;
}
.wa-chat-bubble-pos-right {
  right: 20px;
}
@media screen and (max-width: 767px) {
  .wa-chat-bubble-pos-left {
    left: 0px;
  }
  .wa-chat-bubble-pos-right {
    right: 0px;
  }
}
.wa-share-btn-container {
  width: auto;
  height: auto;
  margin: 0;
  bottom: 50%;
  font-size: 16px;
  position: fixed;
  cursor: pointer;
  padding: 1px 4px;
  box-shadow: 0 0 5px #d3d3d3;
  line-height: 1.5;
  font-family: Lato, Open Sans, sans-serif !important;
}
.wa-share-btn-cta {
  writing-mode: tb;
  margin: 6px 0;
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -webkit-writing-mode: tb;
  -ms-writing-mode: tb;
  display: table;
  color: #fff;
}
.wa-share-btn-img {
  display: table;
  height: 20px;
  width: 20px;
  margin: auto auto 4px;
  display: block !important;
}
.wa-share-btn-pos-left {
  left: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.wa-share-btn-pos-right {
  right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.wa-share-btn-tmpl-regular {
  background: white;
}
.wa-share-btn-tmpl-regular p {
  color: green;
}
.wa-share-btn-tmpl-inverted {
  background: #35cb5e;
}
.wa-share-btn-tmpl-inverted p {
  color: #fff;
}
.wa-share-btn-tmpl-black-regular {
  background: white;
}
.wa-share-btn-tmpl-black-regular p {
  color: #000;
}
.wa-share-btn-tmpl-black-inverted {
  background: black;
}
.wa-share-btn-tmpl-black-inverted p {
  color: #fff;
}
.wa-share-btn-tmpl-old {
  background: #f9f9f9;
}
.wa-share-btn-tmpl-old p {
  color: green;
}
@media screen and (max-width: 500px) {
  .wa-optin-widget-container {
    width: 98%;
  }
}
.wa-optin-widget-logo-img {
  width: 92%;
}
.wa-optin-widget-number-input {
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
  padding: 10px;
  height: 38px;
  font-size: 16px;
  width: 56%;
  box-sizing: border-box;
  background-color: #fff;
  color: #000;
}
.wa-optin-widget-confirm-button {
  display: inline-block;
  vertical-align: middle;
  height: 42px;
  background: #2eb840;
  color: #fff;
  border-style: solid;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  right: 24px;
  border: none;
  outline: none;
  font-size: 16px;
  width: 36%;
  border-radius: 0 100px 100px 0;
  -webkit-border-radius: 0px 100px 100px 0px;
  -moz-border-radius: 0px 100px 100px 0px;
  -ms-border-radius: 0px 100px 100px 0px;
  -o-border-radius: 0px 100px 100px 0px;
}
.wa-optin-widget-confirm-button:hover {
  box-shadow: 2px 2px 16px #0003;
}
.wa-chat-widget-footer {
  position: absolute;
  bottom: 0px;
  text-align: center;
  font-family: Lato, Open Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #999;
  width: 100%;
  height: 20px;
  background: #fafafa;
  vertical-align: middle;
  cursor: pointer;
  z-index: 100;
  box-shadow: 0 0 8px #00000014;
  border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0px 0px 4px 4px;
  -moz-border-radius: 0px 0px 4px 4px;
  -ms-border-radius: 0px 0px 4px 4px;
  -o-border-radius: 0px 0px 4px 4px;
}
.wa-chat-widget-footer-superlemon {
  color: #2eb840;
}
.wa-callout-card-close-btn {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
}
.wa-callout-card-close-btn-right {
  top: -22px;
  left: -22px;
}
.wa-callout-card-close-btn-left {
  top: -22px;
  right: -22px;
}
.wa-callout-card-close-btn img {
  height: 100%;
  width: 100%;
  box-shadow: 0 0 4px #0003;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.wa-callout-card-fixed {
  width: 260px;
  padding: 12px;
  bottom: 40px;
  opacity: 0;
  font-size: 14px;
  position: fixed;
  background: #fff;
  cursor: pointer;
  box-shadow: 0 0 5px #d3d3d3;
  transition: all 0.8s ease-in-out;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.callout-text-container {
  position: relative;
  color: #2c2c2c;
}
.callout-text-container p {
  line-height: 1.5;
  margin: 0;
  color: #2c2c2c;
}
.animate-callout-card {
  opacity: 1;
}
.hide-callout-card {
  transform: translateY(200%);
  -webkit-transform: translateY(200%);
  -moz-transform: translateY(200%);
  -ms-transform: translateY(200%);
  -o-transform: translateY(200%);
}
.wa-optin-widget-left-sec,
.wa-optin-widget-right-sec {
  width: 50%;
}
.wa-optin-widget-close-img {
  width: 100%;
  height: auto;
  display: inline-block;
}
.wa-optin-widget-input-box {
  display: flex;
  place-items: center;
  background: #ffffff !important;
  border: 1px solid #c4cdd5;
  box-shadow: inset 0 1px 2px #66717b36;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.wa-optin-widget-right-sec-content-container {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 85%;
  margin: 0 auto;
}
.wa-optin-widget-left-sec {
  background: #f7f7f7;
  padding: 7% 4%;
  overflow: hidden;
}
.wa-optin-widget-right-sec {
  background-image: url(https://cdn.shopify.com/s/files/1/0070/3666/5911/files/opt-in-bg-whatsapp-light.png?1433);
  background-image: url(https://cdn.shopify.com/s/files/1/0070/3666/5911/files/opt-in-bg-whatsapp-light.png?1433);
  background-image: url(https://cdn.shopify.com/s/files/1/0070/3666/5911/files/opt-in-bg-whatsapp-light.png?1433);
  background-image: url(https://cdn.shopify.com/s/files/1/0070/3666/5911/files/opt-in-bg-whatsapp-light.png?1433);
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  place-items: center;
  flex-direction: column-reverse;
  padding: 1.3em 0.5em;
  font-size: 16px;
}
.wa-optin-widget-input-box,
.wa-optin-widget-confirm-btn {
  width: 100%;
  overflow: hidden;
  padding: 0.7em 1em;
  font-family: Source Sans Pro, sans-serif;
  height: auto;
}
.wa-optin-widget-input-box {
  box-sizing: border-box;
  padding: 0.8vw 1.6vw;
}
.wa-optin-widget-input,
input[type='tel'].wa-optin-widget-input {
  border: 0 !important;
  padding: 0.3rem !important;
  background: transparent !important;
  width: 100% !important;
  font-family: Source Sans Pro, sans-serif !important;
  font-weight: 400 !important;
  font-size: 1.3vw !important;
  line-height: 1 !important;
  margin: 0 !important;
  color: #000 !important;
  min-height: 0px;
  height: auto;
}
input[type='tel']:focus.wa-optin-widget-input {
  box-shadow: none !important;
}
.wa-optin-widget-country-flag {
  line-height: 0.8;
  line-height: 100%;
  font-size: 1.3vw;
}
.wa-optin-widget-confirm-btn {
  width: 100%;
  color: #00b217;
  background: #ffffff;
  margin-top: 0.8em;
  border: 2px solid #00b217;
  box-shadow: 0 1px #161d251a, inset 0 1px #ffffff0f;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  font-weight: 600;
  font-size: 1.3vw;
  padding: 0.7em 1em;
  line-height: 1.3;
}
.wa-optin-widget-blur-filter {
  filter: blur(1px);
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  background: rgba(0, 0, 0, 0.6);
}
.wa-optin-widget-title {
  font-size: 2.5vw;
  color: #000;
  padding: 0.5em 0;
  font-family: Source Sans Pro, sans-serif;
  font-weight: 600;
  line-height: 140%;
  margin-block-end: auto;
  margin-block-start: auto;
}
.wa-optin-widget-subTitle {
  font-size: 1.5vw;
  color: gray;
  padding: 0.5em 0;
  font-family: Source Sans Pro, sans-serif;
  font-weight: 500;
  line-height: 140%;
  margin-block-end: auto;
  margin-block-start: auto;
}
.wa-optin-widget-title-text-logo {
  height: 1.3em;
  width: 1.3em;
  vertical-align: text-bottom;
  margin-left: 8px;
  margin-right: 4px;
}
.wa-optin-widget-title-text {
  word-wrap: initial;
}
.wa-optin-widget-container {
  padding: 2% 2%;
  background: #f7f7f7;
  display: block !important;
  position: fixed;
  top: 50%;
  left: 50%;
  font-size: 1rem !important;
  width: 80% !important;
  max-width: 70%;
  max-height: 80%;
  box-sizing: border-box;
  box-shadow: 2px 2px 16px #0003;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  overflow: hidden;
}
.wa-optin-widget-content-container {
  font-style: normal;
  display: flex;
  position: relative;
}
.wa-optin-widget-close-btn {
  position: absolute;
  top: 1.2vw;
  right: 1.2vw;
  cursor: pointer;
  height: 1.3vw;
  width: 1.3vw;
}
.wa-optin-widget-confirm-btn-active {
  background: #00b317;
  color: #fff;
}
.wa-optin-widget-list-items {
  padding: 0.3em 0;
  font-size: 1.5vw;
  color: #000;
  font-family: Lato, sans-serif;
  font-weight: 400;
  line-height: 1.6 !important;
}
li.wa-optin-widget-list-items {
  margin-bottom: 0.25em;
  list-style-type: none;
}
.wa-optin-widget-list-items span {
  font-size: 1.5vw;
  color: #000;
  font-family: Lato, sans-serif !important;
  font-weight: 400 !important;
  line-height: 1.6 !important;
}
.wa-optin-widget-list-items img {
  vertical-align: middle;
  height: 1.4em;
  width: 1.4em;
  margin-right: 8px;
}
.wa-optin-widget-input:focus {
  outline: none;
  background: transparent;
}
.wa-optin-widget-ul-container {
  padding-bottom: 1em;
}
.wa-optin-widget-ul-container ul {
  list-style: none;
  padding-left: 0;
  margin: 0 0 30px;
}
.wa-optin-widget-title-container {
  margin-bottom: 2.4vh;
}
.wa-optin-widget-stop-scrolling {
  height: 100%;
  overflow: hidden;
}
.wa-blur-container {
  height: 1000%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #515151e0;
}

.wa-optin-widget-spam-msg {
  position: relative;
  bottom: 10px;
  left: 0px;
  font-size: 85%;
  color: gray;
}

@media (min-width: 310px) and (max-width: 767px) {
  .wa-optin-widget-input,
  input[type='tel'].wa-optin-widget-input,
  .wa-optin-widget-confirm-btn {
    width: 100% !important;
    font-size: 1em !important;
  }
  .wa-optin-widget-country-flag {
    font-size: 1em;
  }
  .wa-optin-widget-confirm-btn {
    margin-top: 12px;
    padding: 0.8em 1em;
  }
  .wa-optin-widget-input-box {
    padding: 0.6em 1em;
  }
  .wa-optin-widget-input,
  input[type='tel'].wa-optin-widget-input {
    padding: 0 0 0 0.6rem !important;
  }
  .wa-optin-widget-left-sec,
  .wa-optin-widget-right-sec {
    width: 100%;
    box-sizing: border-box;
  }
  .wa-optin-widget-left-sec {
    padding: 0;
  }
  .wa-optin-widget-title-container {
    padding: 1% 1% 1%;
    background: #f7f7f7;
    margin-bottom: 0;
  }
  .wa-optin-widget-content-container {
    background: #fff;
  }
  .wa-optin-widget-virtual-keyboard-confirm-btn-margin {
    margin-top: 32px;
  }
  .wa-optin-widget-virtual-keyboard-right-sec-margin-top {
    margin-top: 1em !important;
    padding: 2em 0.5em 2.5em;
  }
}

@media (min-width: 970px) {
  .wa-optin-widget-input,
  input[type='tel'].wa-optin-widget-input {
    padding: 0 0 0 0.6rem !important;
  }
  .wa-optin-widget-left-sec,
  .wa-optin-widget-right-sec {
    width: 100%;
    box-sizing: border-box;
  }
  .wa-optin-widget-left-sec {
    padding: 0;
  }
  .wa-optin-widget-title-container {
    padding: 0% 5% 0% 3%;
    background: #f7f7f7;
    margin-bottom: 0;
  }
  .wa-optin-widget-ul-container {
    position: relative;
    bottom: 0px;
    left: 15px;
  }
  .wa-optin-widget-spam-msg {
    position: relative;
    bottom: 15px;
    left: 15px;
    font-size: 16px;
    color: gray;
  }
  .wa-optin-widget-content-container {
    background: #fff;
  }
  .wa-optin-widget-virtual-keyboard-confirm-btn-margin {
    margin-top: 32px;
  }
  .wa-optin-widget-virtual-keyboard-right-sec-margin-top {
    margin-top: 1em !important;
    padding: 2em 0.5em 2.5em;
  }
}

@media (min-width: 300px) and (max-width: 410px) {
  .wa-optin-widget-title,
  .wa-optin-widget-subTitle {
    font-size: 2.7vw;
    line-height: 140%;
    margin: 0;
  }
}
@media (min-width: 755px) and (max-width: 1200px) {
  .wa-optin-widget-input,
  input[type='tel'].wa-optin-widget-input {
    padding: 0.3vw 0.6vw !important;
  }
}
@media all and (min-width: 1400px) and (max-width: 3720px) {
  .wa-optin-widget-input,
  input[type='tel'].wa-optin-widget-input {
    padding: 0.3vw 0.8vw !important;
    font-size: 20px !important;
  }
  .wa-optin-widget-spam-msg {
    font-size: 20px !important;
  }
}
@media all and (min-width: 1870px) and (max-width: 3720px) {
  .wa-optin-widget-input,
  .wa-optin-widget-confirm-btn {
    line-height: 1;
  }
  .wa-optin-widget-input-box {
    padding: 0.8rem 1rem;
  }
  .wa-optin-widget-right-sec {
    padding: 1.3em 0.5em 2.8em;
  }
}

@-webkit-keyframes bounceDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    -o-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    -moz-transform: translate3d(0, 2000px, 0);
    -ms-transform: translate3d(0, 2000px, 0);
    -o-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@-o-keyframes bounceDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    -o-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    -moz-transform: translate3d(0, 2000px, 0);
    -ms-transform: translate3d(0, 2000px, 0);
    -o-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@keyframes bounceDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    -o-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    -moz-transform: translate3d(0, 2000px, 0);
    -ms-transform: translate3d(0, 2000px, 0);
    -o-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@-moz-keyframes bounceDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    -ms-transform: translate3d(0, 10px, 0);
    -o-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    -o-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    -moz-transform: translate3d(0, 2000px, 0);
    -ms-transform: translate3d(0, 2000px, 0);
    -o-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
.bounceDown {
  -webkit-animation-name: bounceDown;
  -moz-animation-name: bounceDown;
  -ms-animation-name: bounceDown;
  -o-animation-name: bounceDown;
  animation-name: bounceDown;
}
@-webkit-keyframes bounceUp {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    -moz-transform: translate3d(0, 3000px, 0);
    -ms-transform: translate3d(0, 3000px, 0);
    -o-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -10px, 0);
    -moz-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    -o-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 5px, 0);
    -moz-transform: translate3d(0, 5px, 0);
    -ms-transform: translate3d(0, 5px, 0);
    -o-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -2px, 0);
    -moz-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -o-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@-moz-keyframes bounceUp {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    -moz-transform: translate3d(0, 3000px, 0);
    -ms-transform: translate3d(0, 3000px, 0);
    -o-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -10px, 0);
    -moz-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    -o-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 5px, 0);
    -moz-transform: translate3d(0, 5px, 0);
    -ms-transform: translate3d(0, 5px, 0);
    -o-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -2px, 0);
    -moz-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -o-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@-o-keyframes bounceUp {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    -moz-transform: translate3d(0, 3000px, 0);
    -ms-transform: translate3d(0, 3000px, 0);
    -o-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -10px, 0);
    -moz-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    -o-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 5px, 0);
    -moz-transform: translate3d(0, 5px, 0);
    -ms-transform: translate3d(0, 5px, 0);
    -o-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -2px, 0);
    -moz-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -o-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes bounceUp {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    -moz-transform: translate3d(0, 3000px, 0);
    -ms-transform: translate3d(0, 3000px, 0);
    -o-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -10px, 0);
    -moz-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    -o-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 5px, 0);
    -moz-transform: translate3d(0, 5px, 0);
    -ms-transform: translate3d(0, 5px, 0);
    -o-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -2px, 0);
    -moz-transform: translate3d(0, -2px, 0);
    -ms-transform: translate3d(0, -2px, 0);
    -o-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
  }
}
.bounceUp {
  -webkit-animation-name: bounceUp;
  -moz-animation-name: bounceUp;
  -ms-animation-name: bounceUp;
  -o-animation-name: bounceUp;
  animation-name: bounceUp;
}
.wa_animated {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}
.wa-optin-widget-container-old {
  position: fixed;
  bottom: 80px;
  width: 550px;
  height: 150px;
  background: #fafafa;
  border: 1px solid #c5c5c5;
  box-sizing: border-box;
  box-shadow: 2px 2px 16px #0003;
  border-radius: 5px;
  display: table;
}
@media screen and (max-width: 500px) {
  .wa-optin-widget-container-old {
    width: 98%;
  }
}
.wa-optin-widget-close-img-old {
  width: 20px;
  display: inline-block;
  vertical-align: middle;
}
.wa-optin-widget-close-btn-old {
  height: 1px;
  float: right;
  padding: 6px;
  border-radius: 10px;
  display: table;
}
.wa-optin-widget-close-img-old:hover {
  width: 18px;
  cursor: pointer;
}
.wa-optin-widget-content-container-old {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.2px;
  color: #000;
  margin-left: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
}
.wa-optin-widget-logo-img-old {
  width: 92%;
}
.wa-optin-widget-number-input-old {
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
  padding: 10px;
  height: 38px;
  font-size: 16px;
  width: 56%;
  box-sizing: border-box;
  background-color: #fff;
  color: #000;
}
.wa-optin-widget-confirm-button-old {
  display: inline-block;
  vertical-align: middle;
  height: 42px;
  border-radius: 0 100px 100px 0;
  background: #2eb840;
  color: #fff;
  border-style: solid;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  right: 24px;
  border: none;
  outline: none;
  font-size: 16px;
  width: 36%;
}
.wa-optin-widget-confirm-button-old:hover {
  box-shadow: 2px 2px 16px #0003;
}
.wa-chat-btn-base-cta-with-icon img {
  border-radius: 100%;
  -webkit-box-shadow: 0 0 5px 0 #d3d3d3;
  box-shadow: 0 0 5px #d3d3d3;
  cursor: pointer;
}
.wa-chat-btn-base-cta-with-icon img.no-radius {
  border-radius: 0rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.wa-chat-btn-base-cta-with-icon .wa-chat-button-cta-text {
  background-color: #fff;
  padding: 4px 10px;
  margin: 0 8px;
  border-radius: 4px;
  color: #2c2c2c;
  cursor: pointer;
  -webkit-box-shadow: 0 0 5px 0 #d3d3d3;
  box-shadow: 0 0 5px #d3d3d3;
}
.rounded .wa-chat-button-cta-text {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}
.wa-chat-btn-base-cta-with-icon.b-grey .wa-chat-button-cta-text {
  background-color: #e9eff5;
  padding: 4px 9px;
  margin: 0 8px;
  border-radius: 4px;
  color: #707070;
  cursor: pointer;
  -webkit-box-shadow: 0 0 5px 0 #d3d3d3;
  box-shadow: 0 0 5px #d3d3d3;
}
.wa-chat-bubble-header-common {
  text-align: center;
  color: #fff;
  padding: 24px;
}
.wa-chat-bubble-header-common.wavy:after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  border-image-source: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 24 150 28'%3E%3Cdefs%3E%3Cpath id='gentle-wave' d='M-160 46c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z'%3E%3C/path%3E%3C/defs%3E%3Cg%3E%3Cuse xlink:href='%23gentle-wave' x='30' y='3' fill='%23fff'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
  border-image-slice: 0 2 100%;
  border-bottom: solid 24px;
}
.wa-intercom .wa-chat-bubble-header-common {
  padding-bottom: 40px;
}
.wa-intercom .wa-chat-bubble-chat {
  margin: -1.4rem 0.9rem 0.9rem;
  box-shadow: 0 4px 16px #0000001a;
  border-radius: 4px;
  margin-bottom: 26px;
}
.wa-intercom .wa-chat-bubble-chat .list-cs {
  padding: 16px 4px;
}
.wa-intercom .wa-chat-widget-footer {
  box-shadow: none;
  background: transparent;
}
.wa-chat-bubble-header-1,
.wa-chat-bubble-header-101 {
  background: linear-gradient(110.56deg, #20802c 0%, #30bf42 100%);
}
.wa-chat-bubble-header-201 {
  background: linear-gradient(164.25deg, #20802c 18.04%, #30bf42 81.96%);
}
.wa-chat-bubble-avatar .avatar-theme-1,
.avatar-theme-101,
.avatar-theme-201 {
  background: #20802c;
}
.wa-chat-bubble-header-2,
.wa-chat-bubble-header-102 {
  background: linear-gradient(110.56deg, #000000 0%, #6a6a6a 100%);
}
.wa-chat-bubble-header-202 {
  background: linear-gradient(164.25deg, #000000 18.04%, #6a6a6a 81.96%);
}
.wa-chat-bubble-avatar .avatar-theme-2,
.avatar-theme-102,
.avatar-theme-202 {
  background: #000000;
}
.wa-chat-bubble-header-3,
.wa-chat-bubble-header-103 {
  background: linear-gradient(110.56deg, #f52380 0%, #fe8fbb 100%);
}
.wa-chat-bubble-header-203 {
  background: linear-gradient(164.25deg, #f52380 18.04%, #fe8fbb 81.96%);
}
.wa-chat-bubble-avatar .avatar-theme-3,
.avatar-theme-103,
.avatar-theme-203 {
  background: #f62f87;
}
.wa-chat-bubble-header-4,
.wa-chat-bubble-header-104 {
  background: linear-gradient(90deg, #591657 0%, #e22d18 100%);
}
.wa-chat-bubble-header-204 {
  background: linear-gradient(164.25deg, #591657 18.04%, #e22d18 81.96%);
}
.wa-chat-bubble-avatar .avatar-theme-4,
.avatar-theme-104,
.avatar-theme-204 {
  background: #a92332;
}
.wa-chat-bubble-header-5,
.wa-chat-bubble-header-105 {
  background: linear-gradient(90deg, #0f3396 0%, #320c79 0.01%, #20a6f7 100%);
}
.wa-chat-bubble-header-205 {
  background: linear-gradient(164.25deg, #0f3396 18.04%, #20a6f7 81.96%);
}
.wa-chat-bubble-avatar .avatar-theme-5,
.avatar-theme-105,
.avatar-theme-205 {
  background: #2860be;
}
.wa-chat-bubble-header-6,
.wa-chat-bubble-header-106 {
  background: linear-gradient(90deg, #4a0646 0%, #ee3d96 100%);
}
.wa-chat-bubble-header-206 {
  background: linear-gradient(164.25deg, #4a0646 18.04%, #ee3d96 81.96%);
}
.wa-chat-bubble-avatar .avatar-theme-6,
.avatar-theme-106,
.avatar-theme-206 {
  background: #9f2370;
}
.wa-chat-bubble-header-7,
.wa-chat-bubble-header-107 {
  background: linear-gradient(90deg, #4a27c0 0%, #b44df3 100%);
}
.wa-chat-bubble-header-207 {
  background: linear-gradient(164.25deg, #4a27c0 18.04%, #b44df3 81.96%);
}
.wa-chat-bubble-avatar .avatar-theme-7,
.avatar-theme-107,
.avatar-theme-207 {
  background: #7838d6;
}
.wa-chat-bubble-header-8,
.wa-chat-bubble-header-108 {
  background: linear-gradient(90deg, #7751e8 0%, #f95a6c 100%);
}
.wa-chat-bubble-header-208 {
  background: linear-gradient(164.25deg, #7751e8 18.04%, #f95a6c 81.96%);
}
.wa-chat-bubble-avatar .avatar-theme-8,
.avatar-theme-108,
.avatar-theme-208 {
  background: #b655ac;
}
.wa-chat-bubble-header-9,
.wa-chat-bubble-header-109 {
  background: linear-gradient(90deg, #fe2b42 0%, #ffa96a 100%);
}
.wa-chat-bubble-header-209 {
  background: linear-gradient(164.25deg, #fe2b42 18.04%, #ffa96a 81.96%);
}
.wa-chat-bubble-avatar .avatar-theme-9,
.avatar-theme-109,
.avatar-theme-209 {
  background: #ff6c57;
}
.wa-chat-bubble-header-10,
.wa-chat-bubble-header-110 {
  background: linear-gradient(90deg, #0c8f8b 0%, #0edd7d 100%);
}
.wa-chat-bubble-header-210 {
  background: linear-gradient(164.25deg, #0c8f8b 18.04%, #0edd7d 81.96%);
}
.wa-chat-bubble-avatar .avatar-theme-10,
.avatar-theme-110,
.avatar-theme-210 {
  background: #0db684;
}
.wa-chat-bubble-header-11,
.wa-chat-bubble-header-111 {
  background: linear-gradient(110.56deg, #1f8d2c 0%, #2bad3c 100%);
}
.wa-chat-bubble-header-211 {
  background: linear-gradient(164.25deg, #1f8d2c 18.04%, #2bad3c 81.96%);
}
.wa-chat-bubble-avatar .avatar-theme-11,
.avatar-theme-111,
.avatar-theme-211 {
  background: #ffe26f;
}
.wa-chat-bubble-header-12,
.wa-chat-bubble-header-112,
.wa-chat-bubble-header-212 {
  background: linear-gradient(164.25deg, #0b4622 18.04%, #1f8145 81.96%);
}
.wa-chat-bubble-avatar .avatar-theme-12,
.avatar-theme-112,
.avatar-theme-212 {
  background: #ffe26f;
}
.wa-chat-bubble-header-13,
.wa-chat-bubble-header-113,
.wa-chat-bubble-header-213 {
  background: #ed731d;
}
.wa-chat-bubble-avatar .avatar-theme-13,
.avatar-theme-113,
.avatar-theme-213 {
  background: #ffe26f;
}
.wa-chat-bubble-header-14,
.wa-chat-bubble-header-114,
.wa-chat-bubble-header-214 {
  background: linear-gradient(164.25deg, #d14000 18.04%, #e7510f 81.96%);
}
.wa-chat-bubble-avatar .avatar-theme-14,
.avatar-theme-114,
.avatar-theme-214 {
  background: #ffe26f;
}
.wa-chat-bubble-header-15,
.wa-chat-bubble-header-115,
.wa-chat-bubble-header-215 {
  background: #29c765;
}
.wa-chat-bubble-avatar .avatar-theme-15,
.avatar-theme-115,
.avatar-theme-215 {
  background: #ffe26f;
}
.wa-chat-bubble-header-16,
.wa-chat-bubble-header-116,
.wa-chat-bubble-header-216 {
  background: linear-gradient(90deg, #00569e 0%, #27aa41 100%);
}
.wa-chat-bubble-avatar .avatar-theme-16,
.avatar-theme-116,
.avatar-theme-216 {
  background: #ffe26f;
}
.wa-chat-bubble-header-17,
.wa-chat-bubble-header-117,
.wa-chat-bubble-header-217 {
  background: linear-gradient(
    164.25deg,
    #f6c64b 0%,
    #fd7928 0.01%,
    #f6c64a 100%
  );
}
.wa-chat-bubble-avatar .avatar-theme-17,
.avatar-theme-117,
.avatar-theme-217 {
  background: #ffe26f;
}
.wa-chat-bubble-header-18,
.wa-chat-bubble-header-118,
.wa-chat-bubble-header-218 {
  background: linear-gradient(90deg, #345aa6 0%, #7497dd 100%);
}
.wa-chat-bubble-avatar .avatar-theme-18,
.avatar-theme-118,
.avatar-theme-218 {
  background: #ffe26f;
}
.wa-chat-bubble-header-19,
.wa-chat-bubble-header-119,
.wa-chat-bubble-header-219 {
  background: #39b7f9;
}
.wa-chat-bubble-avatar .avatar-theme-19,
.avatar-theme-119,
.avatar-theme-219 {
  background: #ffe26f;
}
.wa-share-btn-theme-6 {
  background: linear-gradient(90deg, #4a27c0 0%, #b44df3 100%);
}
.wa-share-btn-theme-7 {
  background: linear-gradient(90deg, #4a0646 0%, #ee3d96 100%);
}
.wa-share-btn-theme-8 {
  background: linear-gradient(90deg, #0f3396 0%, #320c79 0.01%, #20a6f7 100%);
}
.wa-share-btn-theme-9 {
  background: linear-gradient(90deg, #591657 0%, #e22d18 100%);
}
.wa-share-btn-theme-10 {
  background: linear-gradient(90deg, #0c8f8b 0%, #0edd7d 100%);
}
.wa-share-btn-theme-11 {
  background: linear-gradient(92.88deg, #f52380 0%, #fe8fbb 100%);
}
.wa-share-btn-theme-12 {
  background: linear-gradient(92.88deg, #20802c 0%, #30bf42 100%);
}
.wa-share-btn-theme-13 {
  background: linear-gradient(92.88deg, #000000 0%, #6a6a6a 100%);
}
.wa-share-btn-theme-14 {
  background: linear-gradient(90deg, #fe2b42 0%, #ffa96a 100%);
}
.wa-share-btn-theme-15 {
  background: linear-gradient(90deg, #7751e8 0%, #f95a6c 100%);
}
.wa-chat-btn-p1-gradient {
  background: linear-gradient(
    112.42deg,
    #20802c 0%,
    #20802c 0.01%,
    #30bf42 100%
  );
}
.wa-chat-btn-p2-gradient {
  background: linear-gradient(112.42deg, #f52380 0%, #fe8fbb 100%);
}
.wa-chat-btn-p3-gradient {
  background: linear-gradient(112.42deg, #000000 0%, #6a6a6a 100%);
}
.wa-chat-btn-p4-gradient {
  background: linear-gradient(90deg, #fe2b42 0%, #ffa96a 100%);
}
.wa-chat-btn-p5-gradient {
  background: linear-gradient(90deg, #7751e8 0%, #f95a6c 100%);
}
.wa-chat-btn-p6-gradient {
  background: linear-gradient(90deg, #0f3396 0%, #320c79 0.01%, #20a6f7 100%);
}
.wa-chat-btn-p7-gradient {
  background: linear-gradient(90deg, #0c8f8b 0%, #0edd7d 100%);
}
.wa-chat-btn-p8-gradient {
  background: linear-gradient(90deg, #4a0646 0%, #ee3d96 100%);
}
.wa-chat-btn-p9-gradient {
  background: linear-gradient(90deg, #4a27c0 0%, #b44df3 100%);
}
.wa-chat-btn-p10-gradient {
  background: linear-gradient(90deg, #591657 0%, #e22d18 100%);
}
.wa-chat-btn-theme-cta-black .wa-chat-button-cta-text {
  color: #000;
}
.wa-chat-btn-theme-cta-old .wa-chat-button-cta-text,
.wa-chat-btn-theme-cta-new .wa-chat-button-cta-text {
  color: green;
}
.wa-custom-chat-btn.wa-chat-btn-base-icon,
.wa-custom-chat-btn .wa-chat-btn-base-icon {
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wa-custom-chat-btn .wa-chat-btn-icon-image-only {
  display: inline-block !important;
  width: 34px;
  height: 34px;
}
.wa-custom-chat-btn.wa-chat-btn-base-cta-with-icon {
  display: flex;
  align-items: center;
}
.wa-custom-chat-btn.wa-chat-btn-base-cta-with-icon .wa-chat-btn-base-icon {
  display: inline-flex;
}
.wa-custom-chat-btn.wa-chat-btn-base-cta-with-icon img {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.custom-chat-btn-templates .wa-custom-chat-btn {
  margin: 1.6rem;
}
.wa-custom-icon {
  display: inline-block !important;
  width: 25px;
  height: 25px;
}
.wa-custom-icon.adjust-icon {
  margin-top: 0.5rem;
}
.w-30 {
  width: 30px !important;
}
.w-3 {
  width: 52px !important;
  height: 52px !important;
}
.w-6 {
  width: 6rem !important;
  height: 6rem !important;
}
.wa-share-icon {
  background-size: cover;
  background: white;
}
.wa-share-mask {
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  -webkit-mask-image: url(https://cdn.shopify.com/s/files/1/0265/2572/8803/files/wa.svg?v=1586952948);
}
.wa-chat-btn-theme-cta-grey,
.wa-chat-btn-theme-cta-grey-dark-green {
  background: #e9eff5;
}
.wa-chat-btn-theme-cta-grey .wa-chat-button-cta-text {
  color: #707070;
}
.wa-chat-btn-theme-cta-grey-dark-green .wa-chat-button-cta-text {
  color: #0f5028;
}
.wa-chat-btn-base-cta.wa-chat-btn-theme-cta-orange,
.wa-chat-btn-base-cta.wa-chat-btn-theme-cta-blue-green,
.wa-chat-btn-base-cta.wa-chat-btn-theme-cta-light-green-comment-dots,
.wa-chat-btn-base-cta.wa-chat-btn-theme-cta-orange-yellow,
.wa-chat-btn-base-cta.wa-chat-btn-theme-cta-blue-yellow {
  background: white;
}
.wa-chat-btn-theme-cta-orange .wa-chat-button-cta-text {
  color: #ed731d;
}
.wa-chat-btn-theme-cta-light-green-comment-dots .wa-chat-button-cta-text {
  color: #29c765;
}
.wa-chat-btn-theme-cta-blue-green .wa-chat-button-cta-text {
  color: #acacac;
}
.wa-chat-btn-base-cta.wa-chat-btn-theme-cta-orange-yellow
  .wa-chat-button-cta-text {
  color: #fd7928;
}
.wa-chat-btn-base-cta-with-icon.wa-chat-btn-theme-cta-orange-yellow
  .wa-chat-button-cta-text {
  color: #acacac;
}
.wa-chat-btn-theme-cta-blue-yellow .wa-chat-button-cta-text {
  color: #345aa6;
}
.wa-chat-btn-base-cta-rounded.wa-chat-btn-theme-cta-light-blue {
  border: 1px solid #39b7f9;
  background: white;
}
.wa-chat-btn-theme-cta-light-blue .wa-chat-button-cta-text {
  color: #39b7f9;
}
.wa-chat-btn-theme-cta-light-blue.rounded .wa-chat-button-cta-text {
  color: #39b7f9;
  border: 1px solid #39b7f9;
  padding: 4px 8px;
}
.wa-chat-btn-base-cta.wa-chat-btn-theme-cta-orange-bg-gradient,
.wa-chat-btn-base-cta-with-icon.wa-chat-btn-theme-cta-orange-bg-gradient
  .wa-chat-button-cta-text {
  background: linear-gradient(270deg, #e7510f 0%, #ff9300 100%);
  color: #fff;
}
.input-country-code {
  width: 5rem !important;
  border-right: 1px solid grey !important;
}
.spin-wheel-btn-fixed {
  position: fixed;
}
.spin-wheel-btn-fixed img {
  background-color: transparent;
  opacity: 1;
  cursor: pointer;
}
.spinwheel-bubble-pos-left {
  left: 20px;
}
.spinwheel-bubble-pos-right {
  right: 20px;
}
@media screen and (max-width: 767px) {
  .spinwheel-bubble-pos-left {
    left: 0px;
  }
  .spinwheel-bubble-pos-right {
    right: 0px;
  }
  #spin-wheel-info {
    top: 100px;
  }
}
.spinwheel-bubble-floating-popup {
  position: fixed;
  box-shadow: 0 0 30px #0000004d;
  background: #fff;
  overflow: hidden;
  width: 60%;
  font-family: Lato, Open Sans, sans-serif !important;
  font-size: 14px;
  line-height: 1.4;
  bottom: 20px;
  display: block;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  color: #333232;
}
@media (min-width: 540px) and (max-width: 768px) {
  .spinwheel-bubble-floating-popup {
    width: 70%;
  }
}
@media (min-width: 1200px) {
  .spinwheel-bubble-floating-popup {
    width: 50%;
    max-height: 95%;
  }
}
.spinwheel-bubble-header {
  padding: 12px;
}
.spinwheel-bubble-close-btn {
  float: right;
  cursor: pointer;
  display: table;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  filter: invert(1);
}
.spinwheel-bubble-widget-container {
  background: #ffffff;
  height: auto;
  overflow-y: hidden;
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
}
.spinwheel-bubble-widget-container #wheel-of-fortune {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: -25%;
}
.spinwheel-bubble-widget-container #spin-wheel-info {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#spin-wheel-info .game-status-txt {
  text-align: center;
  padding: 0 14px;
  font-family: Lato, Open Sans, sans-serif !important;
  color: #3a3a3a;
  font-weight: 700;
  margin: 0;
  font-size: 30px;
}
@media (min-width: 540px) and (max-width: 768px) {
  #spin-wheel-info .game-status-txt {
    font-size: 22px;
  }
}
#spin-wheel-info .game-status-subtxt {
  font-size: 14px;
  text-align: center;
  color: #6d7175;
  padding: 0 14px;
  font-weight: 400;
}
#spin-wheel-info button {
  width: 80%;
  color: #000;
  background-color: #f9cacd;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  border-color: transparent;
  margin-bottom: 20px;
  margin-top: 20px;
}
.spin-wheel-game-rules {
  font-size: 11px;
}
.spin-wheel-game-rules p {
  margin-bottom: 0;
  margin-left: -24px;
}
.spin-wheel-game-rules li {
  list-style: disc;
  color: #6d7175;
}
.spin-wheel-note-txt {
  text-align: center;
  color: #6d7175;
  font-size: 11px;
  padding: 0 14px;
}
#spin-wheel-selector {
  position: relative;
  top: 46%;
  z-index: 3;
  transform: rotate(90deg);
}
#spin-wheel-info .wa-optin-widget-input-box {
  padding: 0;
  width: 80%;
}
#spin-wheel-info .input-country-code {
  width: 4rem !important;
}
#spin-wheel-info .input-country-code-select {
  font-size: 16px;
}
.spin-wheel-game-rules ul {
  padding-left: 0;
}

@media screen and (max-width: 540px) {
  .spinwheel-bubble-header {
    position: sticky;
    top: 0;
    right: 0;
  }
  .game-status-txt {
    font-size: 28px;
    padding: 0 70px;
    font-weight: 700;
  }
  .spin-wheel-game-rules {
    display: none;
  }
  #spin-wheel-info button {
    font-size: 20px;
    padding: 8px;
  }
  .spinwheel-bubble-floating-popup {
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    font-size: 12px;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    height: 100%;
  }
  .spinwheel-bubble-widget-container {
    flex-direction: column-reverse;
    align-items: center;
    height: 95%;
    overflow: hidden;
    gap: 70px;
  }
  .spinwheel-bubble-widget-container #wheel-of-fortune {
    left: unset;
    width: 100%;
  }
  .spinwheel-bubble-widget-container #spin-wheel-info {
    position: relative;
    width: 95%;
    height: 50%;
  }
  #spin-wheel-info .wa-optin-widget-input-box {
    padding: 15px 1em;
  }
  #spin-wheel-info .wa-optin-widget-input-box input {
    font-size: 16px;
  }
  .game-status-subtxt {
    padding: 0 50px;
  }
  #spin-wheel-confirm-btn {
    margin-bottom: 0;
  }
  #spin-wheel-selector {
    top: 10px;
    right: -45%;
    transform: rotate(0);
  }
  #spin-wheel-info .input-country-code-select {
    width: 10rem !important;
    padding-left: 0 !important;
    text-align: center;
    font-size: 16px;
  }
}
.spinWheelBottom {
  height: 50%;
  bottom: -23%;
}
.input-country-code-select {
  max-width: 30%;
}

@media screen and (max-device-width: 767px) {
  .wa-optin-widget-content-container {
    background: #fff;
    display: block !important;
    text-align: center;
  }
  .wa-optin-widget-input,
  input[type='tel'].wa-optin-widget-input {
    font-size: 3.5vw !important;
  }
  .wa-optin-widget-confirm-btn {
    font-size: 2vw !important;
  }
  .wa-optin-widget-confirm-btn-active {
    font-size: 2vw !important;
  }
  .wa-optin-widget-title {
    font-size: 4vw !important;
  }
  .wa-optin-widget-subTitle {
    font-size: 3vw !important;
  }
  .wa-optin-widget-list-items {
    font-size: 3vw !important;
  }
  .wa-optin-widget-list-items span {
    font-size: 3vw !important;
  }
  .wa-optin-widget-spam-msg {
    font-size: 3vw !important;
    position: relative;
    left: -5%;
  }
  .wa-optin-widget-close-btn img {
    width: 3vw;
    height: 3vw;
  }
  .wa-optin-widget-right-sec {
    text-align: center;
  }
  .wa-optin-widget-ul-container {
    text-align: left !important;
    position: relative;
    bottom: 0px;
    left: 25%;
  }
}
/*# sourceMappingURL=/s/files/1/0070/3666/5911/files/superlemon_4a10a24e-3d02-4376-8804-a35728a01092.css.map?v=1672228315 */
